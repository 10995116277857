// Relativ path to images folder (used in the image-url() function)
$imagePath: "../images/" !default;

// +++++ Font settings
// Font size of main content (in px)
$baseFontSize: 19px;
$baseFontSizeNew: 19px;
 
// Line height of main content (in px)
$baseLineHeight: 30px;
$baseLineHeightNew: 28px;

// Headline font sizes (in px)
$h1Size: 36px;
$h2Size: 32px;
$h3Size: 28px;
$h4Size: 26px;
$h5Size: 24px;
$h6Size: 20px;  

// Font stacks: $mainFont for simple content, $displayFont for headlines etc., $iconFont for icons
$mainFont:    "Roboto", sans-serif;
$displayFont: "EB Garamond", serif;
$iconFont:     sans-serif;
$iconFont:    "FontAwesome";   
// $iconFont:   "icomoon"; 

// +++++ Design & Layout
// Colours for your design.
$primary:   #717078;
$secondary: #0077c0; 
$light:     #fff;
$medium:    #F2F2F0;
$dark:      #262D3B;
$border:    #bbb;
$alert:     #D85D00;

// Horizontal padding left and right of grid columns
$baseGap: 18px;

// Grid row max-width
$rowMaxWidth: 1826px; 

// Grid columns
$columnCount: 12;

// Standard border for your design
$baseBorder: 1px solid $border;

// Standart outline styles for focus states
$outline: 1px dotted $alert;

// Assume a different base font size for breakpoint em calculation (default: 16px)
$bpContext: 16px;

// +++++ Miscellaneous
// text-indent value when using hideText(): "right" = positive value, "left" = negative value
$hideTextDirection: "right";

$iconMap: (
    times: "×",
    bars: "≡",
    minus: "-",
    angle-right: "❯",
    plus: "+",
    angle-up: "↑",
    exclamation: "!"
);

// EXAMPLE FOR USE WITH ICONFONT
// $iconMap: (
//     times: "\f00d",
//     bars: "\f0c9",
//     minus: "\f068",
//     angle-right: "\f054",
//     plus: "\f067",
//     angle-up: "\f077",
//     exclamation: "\f06a"
// );


/*  Grid Map 
    Define your breakpoints and grid classes below.
    Only generate the gridclasses you really need to reduce filesize.
    example:
    tiny: ( <-- "breakpoint name"
        width:  em(480px, $bpContext), <-- "breakpoint width"
        cols: (5,6,12), <-- "columns" result generates .tiny-5, .tiny-6 and .tiny-12
        prefix: (2,4), <-- "prefix" result generates .tiny-prefix-2 and .tiny-prefix-4
        suffix: (6,8), <-- "suffix" result generates .tiny-suffix-6 and .tiny-suffix-8
    )
*/
$gridMap: (
    tiny: (
        width:  em(480px, $bpContext),
        cols: (3,6,5,7)
    ),
    small: (
        width:  em(640px, $bpContext),
        cols: (5,6)
    ),
    medium: ( 
        width:  em(800px, $bpContext),
        cols: (2,3,4,6),
        prefix: (3),
        suffix: (3)    
    ),
    large: (
        width:  em(992px, $bpContext),
        cols: (3,8,9,6,10,4,5,2,12,7) 
    ),   
    giant: (
        width:  em(1200px, $bpContext),
        cols: (3,9,6,4,2),
        suffix: (1)       
    ),
    huge: (
        width:  em(1364px, $bpContext),
        cols: (3,5,4,6) 
    ),
    full: (
        width:  em(1520px, $bpContext),
    ),
    fullx:(
         width:  em(1800px, $bpContext),
         cols: (10)
    )
); 