// if you want to use more file types, write: $type: woff ttf woff2 (no comma separation!)

// @include font-face(
//  $name: "icomoon",
//  $file: "icomoon",
//  $weight: 400,
//  $style: normal,
//  $type: woff
// );

@include font-face(
    $name: "FontAwesome",
    $file: "fa-regular-400",
    $weight: 400,
    $style: normal,
    $type: woff
);

@include font-face(
    $name: "FontAwesome",
    $file: "fa-solid-900",
    $weight: 900,
    $style: normal,
    $type: woff
);

@include font-face(
    $name: "Roboto",
    $file: "Roboto-Bold",
    $weight: 700,
    $style: normal,
    $type: woff
);
@include font-face(
    $name: "Roboto",
    $file: "Roboto-Light",
    $weight: 300,
    $style: normal,
    $type: woff
);
@include font-face(
    $name: "Roboto",
    $file: "Roboto-Medium",
    $weight: 500,
    $style: normal,
    $type: woff
);
@include font-face(
    $name: "Roboto",
    $file: "Roboto-Regular",
    $weight: 400,
    $style: normal,
    $type: woff
);

@include font-face(
    $name: "EB Garamond", 
    $file: "EBGaramond-Regular",
    $weight: 400,
    $style: normal,
    $type: woff
);
