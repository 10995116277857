// GLOBAL SETTINGS
//////////////////////////////////////////////

$break: large; // Breakpoint – switch to Desktop version

$desktopHover: true; // Set Hover to reach Submenus for Desktop (true, false)

// NAVIGATION
////////////////////////////////////////////// 

$navBg: $light; // Navigation Background
$navColor: $dark; // Navigation Link Color

@mixin navButton {
	// Default Link Styles
	padding: 1em rem($baseGap);
	text-decoration: none;
	color: $navColor;
}		
%buttonHover {
	// Link Hover
	color: $light;
	background-color: $secondary;
	&:after{
		color: $light;
	}
}
%buttonActive {
	// Link Active
	color: $light;
	background-color: $secondary;
	&:after{
		color: $light;
	}
} 
%prevIcon {
	// Icon to previus Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);
	transform: rotateY(180deg);
}
%nextIcon {
	// Icon to next Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);
}
$closeIcon: map-get($iconMap, times); // Icon to close Submenu

// Quickbar
//////////////////////////////////////////////

$barHeight: 50px; // height (in px)
$barPos: top; // position (top, bottom)
$barHide: true; // if bar hides on scroll (true, false)

$barBg: $dark; // background-color
$barColor: $light; // children colors – texts and burgerbutton

//////////////////////////////////////////////
// NAVIGATION FUNCTIONALITY
//////////////////////////////////////////////

@import "../modules/ruffnav";

// Navigation Styles
////
@include pageNavi {
	background-color: $navBg;
	z-index: 1001;

	nav {
		@include ul {
			&.navi {
				li {
					@include navButtons {
						@include navButton;
					}
					@include hasSub {
						@include sub {
							background: $navBg;

							&:before {
								@extend .h5;
								padding: 1em rem($baseGap);
								margin-bottom: 0;
								background-color: darken($navBg, 6%);
							}
						}
					}
					@include navBack {
						font-size: 1rem;
					}
				}
			}
		}
	}
}

// Navigation Desktop Styles
////
@include breakpoint($break) {
	@include desktopVersionFunctionality;

	#pageNavi {
		padding:rem(23px) 0;
		&.fixed{  
	    	box-shadow: 0 5px 5px -5px #ccc;  
	    }
		nav {
			ul {
				padding:rem(27px) 0 rem(24px); 
				@include breakpoint(giant) { 
					padding:rem(41px) 0 rem(24px);
				}    
				&.navi {
					> li {
						position:relative;
						a, span{
							font-size: rem(14px);
						    text-transform:uppercase;
							font-weight:500;
							padding:0;
							color:#000000;
							@include breakpoint(giant) {
								font-size: rem(16px);
							}
							@include breakpoint(huge) {
								font-size: rem(20px);
							}
						}
						&:hover, &:focus, &.active{
							>a, >span{
								background:transparent;
								color:$secondary;
							} 
						} 
						&.hasSub { 
							span{
								padding:0;
							}
							// &.about{
							// 	> .sub { 
							// 		right:rem(-65px); 
							// 		left:auto;
							// 		transform:none;
							// 	}
							// }
							span{ 
								&:after { 
						        content:'\f107';  
						        font-family:$iconFont; 
						      }  
							}
							// &.professionals{
							// 	> .sub { 
							// 		li {
							// 			.sub {
							// 				height: 50vh;
							// 				overflow-y: scroll;
							// 				a {
							// 					padding:rem(1px) 1.05263rem; 
							// 					@include breakpoint(huge) {
							// 						padding:rem(4px) 1.05263rem; 
							// 					}
							// 				}
							// 			}
										
							// 		}
							// 	}
							// }

							> .sub { 
								padding:0; 
								left:50%;
								transform:translate(-50%);
								box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 3px;        
								[data-parent-name="CAREERS"]{
									left: 0;
									text-align: right; 
								}
								&:after{
									position:absolute;
									top:rem(-48px);
									height:rem(48px);
									background:transparent;
									content:'';
									left:0;
									width:100%;
								}
								&:hover{
									li{
										 
									  &.nav-back {
									  	body:not(.touchdevice) & {
						                  display: none;
						                  opacity:0;
						                  visibility:hidden; 
						                }
									  } 
									}
								} 
								> li{ 
			                      &:not(:last-child) {
										border-bottom:1px solid #000000; 
									}
									>a, > span{
										padding:rem(10px) rem(20px);  
										display:block;
										font-size: rem(14px); 
										background:$light;
										@include breakpoint(giant) {
											font-size: rem(13px);
										}
										 
									}
									&.hasSub{
										>a, > span{ 
											&:after {
									        content:'\f105';   
									      }
										}
									} 
									.sub{   
										left:100%;
										transform:none; 
										padding:0; 
										max-height: 300px;
										overflow-y: auto;
										box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 3px;
										@include breakpoint(giant) {
											left:100%;
											right:auto;
										}
										li{
											border-bottom:1px solid #fff;
											&:last-child {
												border-bottom:none;
											}
											a{
												text-transform:none;
												font-size: rem(14px); 
												color:$light; 
												background:$secondary;
												padding:.52632rem 1.05263rem; 
												@include breakpoint(giant) {
													font-size: rem(16px);
												} 
											}
											&:hover, &:focus, &.active{
												>a, > span{
													color:$light;
													background:$dark; 

												}
											}
										}
									}
									&:after{
										display:none;
									}
									&:hover, &:focus, &.active { 
										>a, > span{  
											background:$secondary;
											color:$light; 
											 &:after {
											 	color:$light;
											 }
										} 
									}
								}
							}
							&:hover, &:focus, &.active{
								>a, > span{
									background:transparent;
									color:$secondary;  
									&::after {
										color: $secondary;
									}
								}
							} 
						}
						&.privacyPolicy{
							display:none;
						}
						&.nav-back {
						}
					}
				}
			}
		}
	}
}

// Quickbar Styles
////
#quickbar {
	@extend %animatedTransform;
	background-color: $secondary;
	color: $light; 
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: rem($baseGap);
	z-index: 1000;

	@include breakpoint($break) {
		display: none;
	}

  .link-container{
    width: 176px;
    display: flex;
    justify-content: space-between;
    align-items: center;

	a{
		&[href*="linkedin"]{
			img{
				width: rem(28px);
			}
			&:hover,
			&:active,
			&:focus{
				opacity: 0.7;
			}
		}
	}
  }
} 